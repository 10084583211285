import { useApolloClient } from "@apollo/client";
import {
  queryShopEligibleForSubscriptionMigration,
  queryShopSubscriptionGatewayEnabled,
} from "@smartrr/shared/shopifyGraphQL/shop";
import { useEffect, useState } from "react";

import { useToast } from "../app/_sharedComponents/Toast/ToastProvider";

export function useShopSubscriptionGatewayEnabled() {
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [enabled, setEnabled] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    setLoading(true);
    queryShopSubscriptionGatewayEnabled(client)
      .then(res => {
        if (res.type === "error") {
          addToast(`Unable to verify subscription gateway enabled state: ${res.message}`);
          setEnabled(false);
        } else {
          setEnabled(res.body.data.shop.features.legacySubscriptionGatewayEnabled);
        }
        setLoading(false);
      })
      .catch(() => {
        setEnabled(false);
        setLoading(false);
      });
  }, [addToast, client]);

  return {
    loading,
    enabled,
  };
}

export function useShopEligibleForSubscriptionMigration() {
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [enabled, setEnabled] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    setLoading(true);
    void queryShopEligibleForSubscriptionMigration(client).then(res => {
      if (res.type === "error") {
        setEnabled(false);
        addToast(`Unable to verify shop subscription migration eligibility: ${res.message}`);
      } else {
        setEnabled(res.body.data.shop.features.eligibleForSubscriptionMigration);
      }
      setLoading(false);
    });
  }, [addToast, client]);

  return {
    loading,
    enabled,
  };
}
